<template>
  <b-overlay :show="loading">
    <div class="formBoder">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col lg="8" md="8" sm="8" xs="12" offset="2">
              <ValidationProvider name="Name" vid="name" rules="required" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  id="name"
                  label-for="name"
                >
                <template v-slot:label>
                  Name
                </template>
                  <b-form-input
                    id="name"
                    v-model="formFiled.name"
                    placeholder="Enter Payment Media Name"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        <div class="row mt-3">
          <div class="col-sm-3"></div>
          <div class="col text-right">
              <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
              &nbsp;
              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
          </div>
        </div>
      </b-form>
      </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import RestApi, { apiBaseURL } from '@/config'

export default {
  props: ['editItem'],
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      formFiled: {
        name: ''
      },
      errors: [],
      valid: null,
      loading: false
    }
  },
  created () {
    if (this.editItem) {
      this.formFiled = this.editItem
    }
  },
  methods: {
    async submitData () {
      this.loading = true
      let result = ''
      if (this.formFiled.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_payment_media_data', this.formFiled)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_payment_media_data', this.formFiled)
      }
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
        this.loading = false
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
